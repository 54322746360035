import { API_HOSTNAME } from "../constants/apiConstants";

const getHeaders = (extra) => {
  const token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  headers = { ...headers, ...extra };
  return headers;
};

const API = (endpoint, method = "GET", body, extraHeaders = {}, options = {}) =>
  new Promise((resolve, reject) => {
    try {
      const headers = getHeaders(extraHeaders);
      if (options.ignoreStringify) delete headers["Content-Type"];
      fetch(`${API_HOSTNAME}${endpoint}`, {
        method,
        headers,
        body: options.ignoreStringify ? body : JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((res) => {
          // console.log('RES.API:', res);
          if (res.statusCode === 401 && window.location.pathname !== "/login") {
            localStorage.clear();
            window.location.href = "/login";
            return;
          }
          if (res && res.statusCode !== 200) {
            //   Sentry.captureMessage(
            //     `Codfirm.FE.API.Err: ${endpoint} ${res.statusCode} ${JSON.stringify(
            //       res
            //     )}`
            //   );
            return reject(res);
          }
          return resolve(res?.data);
        })
        .catch((error) => {
          // console.log('ERR.API:', error);
          if (
            error.statusCode === 401 &&
            window.location.pathname !== "/login"
          ) {
            localStorage.clear();
            window.location.href = "/login";
            return;
          }
          // Sentry.captureMessage(
          //   `Codfirm.FE.API.Err: ${endpoint} ${JSON.stringify(error)}`
          // );
          return reject(error);
        });
    } catch (err) {
      if (err.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      return reject(err);
    }
  });

export default API;
