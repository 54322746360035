import API from "../api";

const Sentry = ({ file, functionName, error }) => {
  if (!error) return;
  if (error.message === "Failed to fetch") return;
  if (error.message === "Network Error") return;
  if (error.message === "Unexpected end of JSON input") return;
  if (error.message.includes("is not valid JSON")) return;
  if (error?.error?.includes("Wallet balance is low")) return;
  if (error?.error?.includes("Invalid item data")) return;
  if (error?.error?.includes("No payment options")) return;
  if (
    error?.error?.includes(
      "Shipping to cart value under ₹1 is currently unavailable."
    )
  )
    return;
  if (error?.error?.includes("Cart is already converted into order!")) return;

  API(`/sentry`, "POST", {
    message: `[brands-fe::${file}::${functionName}] ${error.message} ::: ${error.error} ::: ${error.requestId}`,
  });
};

export default Sentry;
