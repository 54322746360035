import { Modal, TextContainer, TextStyle } from "@shopify/polaris";
import React from "react";

const ModalMessage = ({ invoiceAmount, showModalPopUp, handleChanges, title }) => {
  return (
      <Modal
        medium
        onClose={handleChanges}
        open={showModalPopUp}
        title={
          <div className="custom-modal-title">
            <TextStyle variation="strong">{title}</TextStyle>
          </div>
        }
        primaryAction={{
          content: "I Understand",
          onAction: handleChanges,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p
            className="modal-body-style"
            >
              <p>
                Kindly pay your pending invoices of Amount{" "}
                <b>{invoiceAmount}</b>
              </p>
              <p className="modal-note-style">
                Note: Please ignore the message if payment is already initiated
              </p>
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
  );
};

export default ModalMessage;
