import "@shopify/polaris/build/esm/styles.css";
import "../styles/globals.css";

import { AppProvider } from "@shopify/polaris";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import en from "@shopify/polaris/locales/en.json";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import API from "../api/index";
import ModalMessage from "../components/PopupNotification/ModalNotification";
import { ZOHO_INVOICE_NOTIFICATION_TIMING } from "../constants/zohoConstant";
import AppWrapper from "../context/state";
import Sentry from "../util/sentry";

// const theme = {
//   logo: {
//     height: 100,
//     topBarSource: "/assets/Logo.svg",
//     contextualSaveBarSource: "/assets/Logo.svg",
//     url: "https://brands.zecpe.com",
//     accessibilityLabel: "brands.zecpe.com",
//   },
// };

const App = (props) => {
  const { Component, pageProps } = props;
  const router = useRouter();

  const handleInputFieldScrollBlur = () => {
    if (typeof window !== "undefined") {
      window.document.addEventListener("wheel", function (event) {
        if (window.document.activeElement.type === "number") {
          window.document.activeElement.blur();
        }
      });
    }
    return;
  };
  const [showModalPopUp, setShowModalPopUp] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  // Check local storage for modal flag on component mount
  useEffect(() => {
    const haveLocalModalInvoice = localStorage.getItem("modalFlag");
    if (haveLocalModalInvoice) {
      const showModal = () => {
        if (haveLocalModalInvoice === "show") {
          // If flag indicates modal has been shown, activate modal
          handleModalInvoiceNotification();
        }
      };
      const interval = setInterval(
        showModal,
        ZOHO_INVOICE_NOTIFICATION_TIMING || 84500000
      ); //right now we are showning popup every 24hrs
      return () => clearInterval(interval);
    } else {
      handleModalInvoiceNotification();
    }
  }, []);

  // Function used to fetch the zoho invoice information
  const handleModalInvoiceNotification = async () => {
    try {
      const localStorageToken = localStorage.getItem("token"); // We need to run this function only for loggedin user
      if (localStorageToken) {
        const zohoInvoiceStatus = await API(
          "/utils/zoho-invoice-status",
          "GET"
        );
        if (zohoInvoiceStatus?.result?.status === "overdue") {
          setShowModalPopUp(true);
          const pendingAmount =
            zohoInvoiceStatus?.result?.currency +
            zohoInvoiceStatus?.result?.pendingAmount;
          setInvoiceAmount(pendingAmount);
          localStorage.setItem("modalFlag", "show");
        } else {
          setShowModalPopUp(false);
          setInvoiceAmount(0);
          if (localStorage.modalFlag) {
            localStorage.removeItem("modalFlag");
          }
        }
      }
    } catch (err) {
      Sentry({
        file: "_app.js",
        functionName: "handleModalInvoiceNotification",
        error: err,
      });
    }
  };

  return (
    <AppWrapper>
      <React.Fragment>
        <Head>
          <title>Ecom360</title>
          <meta charSet="utf-8" />
          <link rel="icon" href="/assets/zecpe-favicon.png" />

          <script
            src="https://accounts.google.com/gsi/client"
            async
            defer
          ></script>
          <script>{handleInputFieldScrollBlur()}</script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.fwSettings={
                'widget_id':1060000000035
                };
                !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
                  `,
            }}
          ></script>
          <script
            type="text/javascript"
            src="https://ind-widget.freshworks.com/widgets/1060000000035.js"
            async
            defer
          ></script>
        </Head>
        <PolarisVizProvider>
          <AppProvider
            linkComponent={(a, b) => {
              return (
                <Link passHref href={a.url} {...a}>
                  <a
                    href={a.url}
                    className={a.className}
                    target={a.external ? "_blank" : "_self"}
                    rel="noreferrer"
                  >
                    {a.children}
                  </a>
                </Link>
              );
            }}
            // theme={theme}
            i18n={en}
          >
            <ModalMessage
              invoiceAmount={invoiceAmount}
              showModalPopUp={showModalPopUp}
              handleChanges={() => setShowModalPopUp(false)}
              title="Payment Pending"
            />
            <Component {...pageProps} key={router.asPath} />
          </AppProvider>
        </PolarisVizProvider>
      </React.Fragment>
    </AppWrapper>
  );
};

export default App;
