// // This file configures the initialization of Sentry on the browser.
// // The config you add here will be used whenever a page is visited.
// // https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_ENV !== "local") {
  Sentry.setTag("invoker", "zecpe-dashboard-fe");
  Sentry.init({
    environment:
      process.env.NEXT_PUBLIC_ENV === "development"
        ? "development"
        : "production",
    dsn:
      SENTRY_DSN ||
      "https://b5f62be006544eeb9aff27f4ee782268@o298611.ingest.sentry.io/6242200",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
