import API from "../api";
import Sentry from "../util/sentry";

// Function used to check whether the user installed Shopify or not based on which we redirect the screen
const ShopifyInstallationCheck = async () => {
    try {
        const response = await API("/utils/codfirm-visibility", "GET", undefined);
            return response.result.data;
    } catch (error) {
        Sentry({
            file: "context/state",
            functionName: "getCodfirmUrl",
            error,
          });
          return false;
    }
}

export default ShopifyInstallationCheck;
