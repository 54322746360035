import { useContext, useEffect, useState } from "react";
import API from "../api/index";
import ShopifyInstallationCheck from "../util/codfirmRedirction";
import Sentry from "../util/sentry";
import AppContext from "./index";

const AppWrapper = ({ children }) => {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showRechargeBanner, setShowRechargeBanner] = useState(true);
  const [codfirmUrl, setCodfirmUrl] = useState("https://apps.shopify.com/cod-order-confirmation-1");

  useEffect(() => {
    if (!store) fetchStore();
  }, []);


  const fetchStore = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true);
        if (window.location.pathname === "/verify") {
          return resolve();
        }
        const token = localStorage.getItem("token");
        if (!token && window.location.pathname !== "/login") {
          window.location.href = "/login";
          setLoading(false);
          return resolve();
        } else if (!token) {
          setLoading(false);
          return resolve();
        }
        const res = await API("/profile").catch((err) => {
          setLoading(false);
          return resolve();
        });
        if (res) {
          setStore(res.result);
          if(res?.result?.storeUrl) getCodfirmUrl(res.result.storeUrl);
          setLoading(false);
          return resolve();
        }
      } catch (err) {
        window.location.href = "/login";
        setLoading(false);
        return resolve();
      }
    });
  };


  const getCodfirmUrl = async (storeURL) => {
    try {
        const storeName = storeURL.split(".")[0];
        const isShopifyInstalled = await ShopifyInstallationCheck();
        if(isShopifyInstalled)
          setCodfirmUrl(`https://admin.shopify.com/store/${storeName}/apps/cod-order-confirmation-2`);
    } catch (err) {
      Sentry({
        file: "context/state",
        functionName: "getCodfirmUrl",
        error: err,
      });
        console.log(err);
    }
}
  return (
    <AppContext.Provider
      value={{
        codfirmUrl,
        store,
        setStore,
        fetchStore,
        loading,
        setLoading,
        showRechargeBanner,
        setShowRechargeBanner,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppWrapper;

export function useAppContext() {
  return useContext(AppContext);
}
